@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --baby-blue: hsla(201, 79%, 74%, 1);
  --tea-rose-red: hsla(7, 58%, 85%, 1);
  --white: hsla(0, 0%, 100%, 1);
  --teal: hsla(184, 51%, 36%, 1);
  --eerie-black: hsla(0, 0%, 10%, 1);
}

body {
  color: var(--eerie-black);
  background: linear-gradient( var(--white), var(--tea-rose-red)) var(--white);
  min-height: 100dvh;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
